import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import Button from '../UI/Button';
import MyLogo from '../../assets/logo-caption.png';

const { Paragraph } = Typography;

const Wrapper = styled.div`
  text-align: center;
  padding: 1.5rem;

  img {
    height: 100%;
    width: 175px;
    margin: 0;
  }
`;

const Content = styled.div`
  margin-bottom: 1rem;

  @media (min-width: 991.98px) {
    margin-right: auto;
    margin-left: auto;
    width: 768px;
  }
`;

export default () => (
  <Wrapper>
    <img src={MyLogo} alt='Jorisan Waterfront Resort' />
    <Content>
      <Paragraph>
        Established in 2017, Jorisan Waterfront Resort is a small but relaxing and private place.. It is seated in just
        a 2500-square meter area along the serene and calm coastal line of the once famous Bauang, La Union. Jorisan
        Waterfront Resort is conceived by the concerted efforts of a simple yet coherent and loving family.
      </Paragraph>
    </Content>
    <Button to='/about-us' isprimary='true'>
      Read More
    </Button>
  </Wrapper>
);
