import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled(Link)`
  padding: 0.6em 0.8em;
  border: 1px Solid transparent;
  margin-right: 5px;

  &:hover {
    background-color: #f1f1f1;
    text-decoration: none !important;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ isprimary }) =>
    isprimary &&
    `
    background-color: #111;
    color: #fff;

    &:hover {
      border-color: #111;
      background-color: transparent;
      color: #5E5050;
    }
  `};

  ${({ isblock }) =>
    isblock &&
    `
   @media (min-width: 1199.98px) {
    display: block;
  }
 `};
`;

export default Wrapper;
