import React from 'react';
import styled from 'styled-components';

import HeroBackground from '../../assets/hero_bg.jpg';

const Wrapper = styled.section`
  height: 35vh;
  background: url(${HeroBackground}) no-repeat;
  background-size: 100% 35vh;
  background-position: top center;

  @media (min-width: 767.98px) {
    background-size: 100% auto;
    background-position: bottom center;
  }

  @media (min-width: 991.98px) {
    height: 100vh;
    background-size: cover;
    background-position: bottom center;
  }
`;

export default () => <Wrapper />;
