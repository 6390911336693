import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

import Container from '../UI/Container';

import Hotel from '../../assets/services_hotel.jpg';
import Bar from '../../assets/services_bar.jpg';
import Pool from '../../assets/services_pool.jpg';
import FunctionRoom from '../../assets/services_func.jpg';

const { Title } = Typography;

const Wrapper = styled(Container)`
  padding-top: 1.5em;
  padding-bottom: 1.5em;
`;

const Content = styled.div`
  background-color: #fff;

  @media (min-width: 767.98px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3em;
  }

  @media (min-width: 1199.98px) {
    width: 1000px;
    margin: 0 auto;
  }
`;

const Card = styled.div`
  text-align: center;
  margin-bottom: 2em;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);

    img {
      transform: scale(1.05);
    }
  }

  @media (min-width: 767.98px) {
    margin-bottom: 0em;
  }
`;

const CardThumbnail = styled.div`
  overflow: hidden;

  img {
    display: inline-block;
    margin-bottom: 0;
    height: auto;
    max-width: 100%;
    transform: scale(1);
  }
`;

const CardContent = styled.div`
  padding: 0.1999999em 1em 1em;
`;

export default () => (
  <Wrapper>
    <Title style={{ textAlign: 'center' }}>We at Jorisan Provide</Title>
    <Content>
      <Card>
        <CardThumbnail>
          <img src={Hotel} alt='Hotel' />
        </CardThumbnail>
        <CardContent>
          <Title level={3}>Hotel</Title>
          <p>Jorisan houses 22 rooms designed and equipped to provide utmost comfort to make your stay worthwhile.</p>
        </CardContent>
      </Card>
      <Card align='right'>
        <CardThumbnail>
          <img src={Bar} alt='Bar' />
        </CardThumbnail>
        <CardContent>
          <Title level={3}>Bar &amp; Restaurant</Title>
          <p>Jorisan serves delightful mix of continental food and Filipino dishes.</p>
        </CardContent>
      </Card>
      <Card>
        <CardThumbnail>
          <img src={Pool} alt='Pool' />
        </CardThumbnail>
        <CardContent>
          <Title level={3}>Pool &amp; Cottage</Title>
          <p>Taking advantage of its small area, Jorisan aims to evoke relaxation in an exclusive mode.</p>
        </CardContent>
      </Card>
      <Card align='right'>
        <CardThumbnail>
          <img src={FunctionRoom} alt='FunctionRoom' />
        </CardThumbnail>
        <CardContent>
          <Title level={3}>Function Room &amp; Pavilion</Title>
          <p>Jorisan offers private and elegant facilities and services for various events</p>
        </CardContent>
      </Card>
    </Content>
  </Wrapper>
);
