import React from 'react';
import Helmet from '../../config/helmet';
import Hero from '../components/Hero';
import About from '../components/About';
import Features from '../components/Features';

export default () => (
  <>
    <Helmet />

    <Hero />
    <About />
    <Features />
  </>
);
